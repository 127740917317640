// src/pages/Pricing.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Pricing.css';

function Pricing() {
  const [isAnnual, setIsAnnual] = useState(true);

  // Función para formatear números
  const formatPrice = (price) => {
    return Number(price).toLocaleString('es-ES', {
      maximumFractionDigits: 0
    });
  };

  const plans = [
    {
      name: "Básico",
      monthlyPrice: 29,
      annualPrice: 290,
      features: [
        "1 Agente de IA",
        "10,000 consultas/mes",
        "Soporte por email",
        "Personalización básica",
        "Integración con chat web",
      ],
      recommended: false,
      buttonText: "Empezar Gratis",
      trialDays: 14
    },
    {
      name: "Profesional",
      monthlyPrice: 79,
      annualPrice: 790,
      features: [
        "3 Agentes de IA",
        "50,000 consultas/mes",
        "Soporte prioritario",
        "Personalización avanzada",
        "Todas las integraciones",
        "Analytics básico",
        "Exportación de datos"
      ],
      recommended: true,
      buttonText: "Prueba Gratuita",
      trialDays: 14
    },
    {
      name: "Empresarial",
      monthlyPrice: 199,
      annualPrice: 1990,
      features: [
        "10 Agentes de IA",
        "Consultas ilimitadas",
        "Soporte 24/7",
        "Personalización total",
        "Todas las integraciones",
        "Analytics avanzado",
        "API acceso completo",
        "Servidor dedicado",
        "SLA garantizado"
      ],
      recommended: false,
      buttonText: "Contactar Ventas",
      trialDays: 30
    }
  ];

  // Función para calcular el precio mensual
  const calculateMonthlyPrice = (plan) => {
    if (isAnnual) {
      // Redondeamos a un número entero para evitar decimales largos
      return Math.round(plan.annualPrice / 12);
    }
    return plan.monthlyPrice;
  };

  return (
    <div className="pricing-page">
      <div className="pricing-header">
        <h1>Planes y Precios</h1>
        <p>Elige el plan perfecto para tu negocio</p>
        
        <div className="billing-toggle">
          <span className={!isAnnual ? 'active' : ''}>Mensual</span>
          <label className="switch">
            <input 
              type="checkbox" 
              checked={isAnnual} 
              onChange={() => setIsAnnual(!isAnnual)}
            />
            <span className="slider"></span>
          </label>
          <span className={isAnnual ? 'active' : ''}>
            Anual
            <span className="save-badge">Ahorra 20%</span>
          </span>
        </div>
      </div>

      <div className="pricing-grid">
        {plans.map((plan, index) => (
          <div 
            key={index} 
            className={`pricing-card ${plan.recommended ? 'recommended' : ''}`}
          >
            {plan.recommended && (
              <div className="recommended-badge">Más Popular</div>
            )}
            
            <div className="plan-header">
              <h2>{plan.name}</h2>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">
                  {formatPrice(calculateMonthlyPrice(plan))}
                </span>
                <span className="period">/mes</span>
              </div>
              {isAnnual && (
                <div className="annual-price">
                  ${formatPrice(plan.annualPrice)} facturado anualmente
                </div>
              )}
            </div>

            <ul className="features-list">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>
                  <svg 
                    className="check-icon" 
                    width="20" 
                    height="20" 
                    viewBox="0 0 20 20"
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      d="M16.7 5.3c.4.4.4 1 0 1.4l-8 8c-.4.4-1 .4-1.4 0l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.3 3.3 7.3-7.3c.4-.4 1-.4 1.4 0z" 
                      fill="currentColor"
                    />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>

            <div className="plan-footer">
              <Link 
                to="/register" 
                className={`plan-button ${plan.recommended ? 'recommended' : ''}`}
              >
                {plan.buttonText}
              </Link>
              <p className="trial-text">
                {plan.trialDays} días de prueba gratis
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Resto del componente igual ... */}
    </div>
  );
}

export default Pricing;