// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import './Navbar.css';

function Navbar() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      {/* Logo y Botón Menú */}
      <div className="navbar-main">
        <Link to="/" className="nav-brand">
          AI Agents Platform
        </Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="menu-icon"></span>
        </button>
      </div>

      {/* Links de Navegación */}
      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        {/* Enlaces públicos */}
        <div className="nav-section">
          <Link to="/" onClick={() => setIsMenuOpen(false)}>Inicio</Link>
          <Link to="/pricing" onClick={() => setIsMenuOpen(false)}>Precios</Link>
          <Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contacto</Link>
          
        </div>

        {/* Enlaces protegidos */}
        {user && (
          <div className="nav-section protected">
            <Link to="/dashboard" onClick={() => setIsMenuOpen(false)}>Dashboard</Link>
            <Link to="/agents" onClick={() => setIsMenuOpen(false)}>Agentes</Link>
            <Link to="/chat" onClick={() => setIsMenuOpen(false)} className="chat-link">
            <span className="chat-icon">💬</span>
            Agente demo con AI
          </Link>
          </div>
        )}

        {/* Botones de autenticación */}
        <div className="auth-section">
          {user ? (
            <div className="user-menu">
              <span className="user-email">{user.email}</span>
              <button onClick={handleLogout} className="logout-button">
                Cerrar Sesión
              </button>
            </div>
          ) : (
            <div className="auth-links">
              <Link to="/login" className="login-button" onClick={() => setIsMenuOpen(false)}>
                Iniciar Sesión
              </Link>
              <Link to="/register" className="register-button" onClick={() => setIsMenuOpen(false)}>
                Registro
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;