// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Componentes públicos
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';  // Importar el componente de Precios
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import N8nChat from './pages/n8n/N8nChat';  // Importar el nuevo componente

// Componentes protegidos
import Dashboard from './pages/dashboard/Dashboard';
import AgentList from './pages/agents/AgentList';
import CreateAgent from './pages/agents/CreateAgent';
import EditAgent from './pages/agents/EditAgent';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="main-content">
          <Routes>
            {/* Rutas públicas */}
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/chat" element={<N8nChat />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* Rutas protegidas */}
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="/agents" element={
              <ProtectedRoute>
                <AgentList />
              </ProtectedRoute>
            } />
            <Route path="/agents/create" element={
              <ProtectedRoute>
                <CreateAgent />
              </ProtectedRoute>
            } />
            <Route path="/agents/:agentId/edit" element={
              <ProtectedRoute>
                <EditAgent />
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;