// src/pages/dashboard/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';

// Componentes del Dashboard
function StatsCard({ title, value, icon, trend }) {
  return (
    <div className="stats-card">
      <div className="stats-header">
        <span className="stats-icon">{icon}</span>
        <span className={`trend ${trend > 0 ? 'positive' : trend < 0 ? 'negative' : ''}`}>
          {trend > 0 ? '↑' : trend < 0 ? '↓' : ''}
          {Math.abs(trend)}%
        </span>
      </div>
      <h3 className="stats-value">{value}</h3>
      <p className="stats-title">{title}</p>
    </div>
  );
}

function AgentCard({ agent }) {
  return (
    <div className="agent-card">
      <div className="agent-header">
        <h3>{agent.name}</h3>
        <span className={`status ${agent.status}`}>{agent.status}</span>
      </div>
      <p className="agent-type">{agent.type}</p>
      <div className="agent-stats">
        <div>
          <span>Consultas</span>
          <span>{agent.queries}</span>
        </div>
        <div>
          <span>Precisión</span>
          <span>{agent.accuracy}%</span>
        </div>
      </div>
      <div className="agent-actions">
        <Link to={`/agents/${agent.id}`}>Ver Detalles</Link>
        <Link to={`/agents/${agent.id}/config`}>Configurar</Link>
      </div>
    </div>
  );
}

function ActivityLog({ activity }) {
  return (
    <div className="activity-item">
      <div className="activity-icon">{activity.icon}</div>
      <div className="activity-content">
        <p>{activity.description}</p>
        <span className="activity-time">{activity.time}</span>
      </div>
    </div>
  );
}

function Dashboard() {
  // Datos de ejemplo
  const stats = [
    { title: "Total Agentes", value: "12", icon: "🤖", trend: 8 },
    { title: "Consultas Hoy", value: "1,234", icon: "💬", trend: 12 },
    { title: "Tasa de Éxito", value: "94%", icon: "✨", trend: -2 },
    { title: "Tiempo Respuesta", value: "1.2s", icon: "⚡", trend: 5 }
  ];

  const agents = [
    {
      id: 1,
      name: "Soporte Técnico AI",
      type: "Soporte",
      status: "active",
      queries: 456,
      accuracy: 95
    },
    {
      id: 2,
      name: "Ventas Assistant",
      type: "Ventas",
      status: "training",
      queries: 234,
      accuracy: 88
    },
    {
      id: 3,
      name: "Medical Advisor",
      type: "Médico",
      status: "active",
      queries: 789,
      accuracy: 97
    }
  ];

  const activities = [
    {
      icon: "🔄",
      description: "Agente 'Soporte Técnico' actualizado",
      time: "Hace 5 minutos"
    },
    {
      icon: "✨",
      description: "Nuevo agente 'Ventas Assistant' creado",
      time: "Hace 2 horas"
    },
    {
      icon: "📈",
      description: "Reporte mensual generado",
      time: "Hace 4 horas"
    }
  ];

  return (
    <div className="dashboard">
      {/* Encabezado */}
      <div className="dashboard-header">
        <h1>Dashboard</h1>
        <Link to="/agents/create" className="create-button">
          + Crear Nuevo Agente
        </Link>
      </div>

      {/* Estadísticas */}
      <div className="stats-grid">
        {stats.map((stat, index) => (
          <StatsCard key={index} {...stat} />
        ))}
      </div>

      {/* Contenido Principal */}
      <div className="dashboard-content">
        {/* Lista de Agentes */}
        <div className="agents-section">
          <h2>Mis Agentes</h2>
          <div className="agents-grid">
            {agents.map((agent) => (
              <AgentCard key={agent.id} agent={agent} />
            ))}
          </div>
        </div>

        {/* Actividad Reciente */}
        <div className="activity-section">
          <h2>Actividad Reciente</h2>
          <div className="activity-list">
            {activities.map((activity, index) => (
              <ActivityLog key={index} activity={activity} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
