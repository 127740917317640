// src/utils/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

// Verifica que las variables de entorno estén definidas
if (!process.env.REACT_APP_SUPABASE_URL) {
    console.error('Missing REACT_APP_SUPABASE_URL');
}
if (!process.env.REACT_APP_SUPABASE_ANON_KEY) {
    console.error('Missing REACT_APP_SUPABASE_ANON_KEY');
}

export const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL || '',
    process.env.REACT_APP_SUPABASE_ANON_KEY || ''
);
