// src/pages/agents/EditAgent.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './EditAgent.css';

function EditAgent() {
  const { agentId } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    type: '',
    description: '',
    personality: '',
    language: '',
    knowledgeBase: [],
    welcomeMessage: '',
    responseTime: '',
    maxTokens: 2000,
  });

  // Simulando la carga de datos del agente
  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        // Aquí iría la llamada a Supabase
        // Por ahora simulamos datos
        const mockAgent = {
          id: agentId,
          name: "Agente de Soporte",
          type: "support",
          description: "Asistente para soporte técnico",
          personality: "professional",
          language: "spanish",
          knowledgeBase: [],
          welcomeMessage: "¡Hola! ¿En qué puedo ayudarte?",
          responseTime: "normal",
          maxTokens: 2000,
        };

        setFormData(mockAgent);
        setIsLoading(false);
      } catch (err) {
        setError('Error al cargar los datos del agente');
        setIsLoading(false);
      }
    };

    fetchAgentData();
  }, [agentId]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    
    if (type === 'file' && files) {
      setFormData(prev => ({
        ...prev,
        knowledgeBase: [...prev.knowledgeBase, ...Array.from(files)]
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    if (error) setError('');
  };

  const handleRemoveFile = (fileIndex) => {
    setFormData(prev => ({
      ...prev,
      knowledgeBase: prev.knowledgeBase.filter((_, index) => index !== fileIndex)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step !== 3 || isSubmitting) return;

    setIsSubmitting(true);
    setError('');

    try {
      // Aquí iría la lógica de actualización en Supabase
      console.log('Actualizando datos:', formData);
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      navigate('/agents');
    } catch (err) {
      setError('Error al actualizar el agente');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep1 = () => (
    <div className="form-step">
      <h2>Información Básica</h2>
      <div className="form-group">
        <label htmlFor="name">Nombre del Agente*</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Ej: Asistente de Soporte Técnico"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="type">Tipo de Agente*</label>
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          required
        >
          <option value="">Selecciona un tipo</option>
          <option value="support">Soporte Técnico</option>
          <option value="sales">Ventas</option>
          <option value="medical">Consultas Médicas</option>
          <option value="educational">Educación</option>
          <option value="custom">Personalizado</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="description">Descripción*</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Describe las funciones y objetivos de tu agente"
          rows="4"
          required
        />
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="form-step">
      <h2>Personalización</h2>
      <div className="form-group">
        <label htmlFor="personality">Personalidad*</label>
        <select
          id="personality"
          name="personality"
          value={formData.personality}
          onChange={handleChange}
          required
        >
          <option value="professional">Profesional</option>
          <option value="friendly">Amigable</option>
          <option value="technical">Técnico</option>
          <option value="casual">Casual</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="language">Idioma Principal*</label>
        <select
          id="language"
          name="language"
          value={formData.language}
          onChange={handleChange}
          required
        >
          <option value="spanish">Español</option>
          <option value="english">Inglés</option>
          <option value="portuguese">Portugués</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="welcomeMessage">Mensaje de Bienvenida*</label>
        <textarea
          id="welcomeMessage"
          name="welcomeMessage"
          value={formData.welcomeMessage}
          onChange={handleChange}
          placeholder="Mensaje que el agente mostrará al iniciar una conversación"
          rows="3"
          required
        />
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="form-step">
      <h2>Base de Conocimiento</h2>
      <div className="form-group">
        <label htmlFor="knowledgeBase">Archivos de Entrenamiento Actuales</label>
        {formData.knowledgeBase.length > 0 && (
          <div className="uploaded-files">
            <ul>
              {formData.knowledgeBase.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <button 
                    type="button" 
                    onClick={() => handleRemoveFile(index)}
                    className="remove-file"
                  >
                    ×
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="form-group">
        <label>Agregar Nuevos Archivos</label>
        <div className="file-upload-container">
          <input
            type="file"
            id="newKnowledgeBase"
            name="knowledgeBase"
            onChange={handleChange}
            multiple
            accept=".pdf,.doc,.docx,.txt"
          />
          <p className="file-help">Arrastra archivos aquí o haz clic para seleccionar</p>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="responseTime">Tiempo de Respuesta*</label>
        <select
          id="responseTime"
          name="responseTime"
          value={formData.responseTime}
          onChange={handleChange}
          required
        >
          <option value="fast">Rápido (Respuestas más cortas)</option>
          <option value="normal">Normal (Balance)</option>
          <option value="thorough">Minucioso (Respuestas detalladas)</option>
        </select>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Cargando datos del agente...</p>
      </div>
    );
  }

  return (
    <div className="edit-agent-page">
      <div className="edit-agent-container">
        <h1>Editar Agente</h1>
        
        <div className="progress-bar">
          <div className={`progress-step ${step >= 1 ? 'active' : ''}`}>1</div>
          <div className={`progress-line ${step >= 2 ? 'active' : ''}`}></div>
          <div className={`progress-step ${step >= 2 ? 'active' : ''}`}>2</div>
          <div className={`progress-line ${step >= 3 ? 'active' : ''}`}></div>
          <div className={`progress-step ${step >= 3 ? 'active' : ''}`}>3</div>
        </div>

        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit}>
          {step === 1 && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}

          <div className="form-buttons">
            {step > 1 && (
              <button 
                type="button" 
                onClick={() => setStep(step - 1)} 
                className="prev-button"
                disabled={isSubmitting}
              >
                Anterior
              </button>
            )}
            
            {step < 3 ? (
              <button 
                type="button" 
                onClick={() => {
                  if (step === 1 && (!formData.name || !formData.type || !formData.description)) {
                    setError('Por favor completa todos los campos obligatorios');
                    return;
                  }
                  if (step === 2 && (!formData.personality || !formData.language || !formData.welcomeMessage)) {
                    setError('Por favor completa todos los campos obligatorios');
                    return;
                  }
                  setError('');
                  setStep(step + 1);
                }}
                className="next-button"
              >
                Siguiente
              </button>
            ) : (
              <button 
                type="submit" 
                disabled={isSubmitting}
                className="submit-button"
              >
                {isSubmitting ? 'Guardando cambios...' : 'Guardar Cambios'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditAgent;
