// src/pages/agents/AgentList.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AgentList.css';

function AgentCard({ agent, onDelete, onToggleStatus }) {
  return (
    <div className="agent-card">
      <div className="agent-header">
        <h3>{agent.name}</h3>
        <span className={`status-badge ${agent.status}`}>
          {agent.status === 'active' ? 'Activo' : 'Inactivo'}
        </span>
      </div>
      <div className="agent-type">
        <span className="type-label">Tipo:</span> {agent.type}
      </div>
      <div className="agent-stats">
        <div className="stat">
          <span>Consultas</span>
          <strong>{agent.queries}</strong>
        </div>
        <div className="stat">
          <span>Precisión</span>
          <strong>{agent.accuracy}%</strong>
        </div>
      </div>
      <div className="agent-actions">
        <button 
          onClick={() => onToggleStatus(agent.id)} 
          className={`status-toggle ${agent.status}`}
        >
          {agent.status === 'active' ? 'Desactivar' : 'Activar'}
        </button>
        <Link 
          to={`/agents/${agent.id}/edit`} 
          className="edit-button"
        >
          Editar
        </Link>
        <button 
          onClick={() => onDelete(agent.id)} 
          className="delete-button"
        >
          Eliminar
        </button>
      </div>
    </div>
  );
}
function AgentList() {
  // Estado para los agentes (simulado, luego se conectará con Supabase)
  const [agents, setAgents] = useState([
    {
      id: 1,
      name: "Soporte Técnico AI",
      description: "Asistente especializado en resolver problemas técnicos y dar soporte a usuarios.",
      type: "Soporte",
      status: "active",
      queries: 1234,
      accuracy: 95
    },
    {
      id: 2,
      name: "Ventas Assistant",
      description: "Agente diseñado para asistir en procesos de ventas y atención al cliente.",
      type: "Ventas",
      status: "inactive",
      queries: 856,
      accuracy: 92
    },
    {
      id: 3,
      name: "Medical Advisor",
      description: "Asistente médico para consultas básicas y programación de citas.",
      type: "Médico",
      status: "active",
      queries: 2456,
      accuracy: 98
    }
  ]);

  // Estado para el filtro y búsqueda
  const [filters, setFilters] = useState({
    status: 'all',
    type: 'all',
    search: ''
  });

  // Manejadores de eventos
  const handleDelete = (agentId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este agente?')) {
      setAgents(agents.filter(agent => agent.id !== agentId));
    }
  };

  const handleToggleStatus = (agentId) => {
    setAgents(agents.map(agent => {
      if (agent.id === agentId) {
        return {
          ...agent,
          status: agent.status === 'active' ? 'inactive' : 'active'
        };
      }
      return agent;
    }));
  };

  // Filtrar agentes
  const filteredAgents = agents.filter(agent => {
    const matchesStatus = filters.status === 'all' || agent.status === filters.status;
    const matchesType = filters.type === 'all' || agent.type === filters.type;
    const matchesSearch = agent.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                         agent.description.toLowerCase().includes(filters.search.toLowerCase());
    
    return matchesStatus && matchesType && matchesSearch;
  });

  return (
    <div className="agents-page">
      <div className="agents-header">
        <h1>Mis Agentes</h1>
        <Link to="/agents/create" className="create-button">
          + Crear Nuevo Agente
        </Link>
      </div>

      <div className="filters-section">
        <input
          type="text"
          placeholder="Buscar agentes..."
          value={filters.search}
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          className="search-input"
        />
        <select
          value={filters.status}
          onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          className="filter-select"
        >
          <option value="all">Todos los estados</option>
          <option value="active">Activos</option>
          <option value="inactive">Inactivos</option>
        </select>
        <select
          value={filters.type}
          onChange={(e) => setFilters({ ...filters, type: e.target.value })}
          className="filter-select"
        >
          <option value="all">Todos los tipos</option>
          <option value="Soporte">Soporte</option>
          <option value="Ventas">Ventas</option>
          <option value="Médico">Médico</option>
        </select>
      </div>

      <div className="agents-grid">
        {filteredAgents.map(agent => (
          <AgentCard
            key={agent.id}
            agent={agent}
            onDelete={handleDelete}
            onToggleStatus={handleToggleStatus}
          />
        ))}
      </div>

      {filteredAgents.length === 0 && (
        <div className="no-results">
          <p>No se encontraron agentes que coincidan con los filtros.</p>
        </div>
      )}
    </div>
  );
}

export default AgentList;
