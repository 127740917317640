// src/pages/n8n/N8nChat.js
import React, { useEffect, useState, useCallback } from 'react';
import '@n8n/chat/style.css';
import { createChat } from '@n8n/chat';
import './N8nChat.css';

function N8nChat() {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Verificar variables de entorno
        const checkEnvironmentVariables = () => {
            console.log('Verificando variables de entorno...');
            console.log('WEBHOOK URL:', process.env.REACT_APP_N8N_WEBHOOK_URL);
            
            if (!process.env.REACT_APP_N8N_WEBHOOK_URL) {
                throw new Error('La URL del webhook no está configurada en las variables de entorno');
            }
            
            return {
                webhookUrl: process.env.REACT_APP_N8N_WEBHOOK_URL
            };
        };

        const initChat = async () => {
            try {
                // Verificar variables de entorno
                const config = checkEnvironmentVariables();
                console.log('Configuración cargada:', config);

                setIsLoading(true);
                setError(null);

                const sessionId = 'session_' + Date.now();

                await createChat({
                    webhookUrl: config.webhookUrl, // Usar la URL verificada
                    mode: 'fullscreen',
                    target: '#n8n-chat',
                    defaultOpen: true,
                    buttonType: 'none',
                    showButton: true,
                    defaultLanguage: 'es',
                    loadPreviousSession: false,
                    useSessionStorage: false,
                    chatInputKey: 'chatInput',
                    showWelcomeScreen: false,
                    chatSessionKey: 'sessionId',
                    
                    metadata: {
                        sessionId: sessionId
                    },
                    webhookConfig: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    },
                    events: {
                        onStart: () => {
                            console.log('Chat iniciado con URL:', config.webhookUrl);
                            setIsLoading(false);
                            setError(null);
                        },
                        onError: (errorMsg) => {
                            console.error('Error en el chat:', errorMsg);
                            setError(errorMsg);
                            setIsLoading(false);
                        }
                    },
                    initialMessages: [
                        '¡Hola! 👋',
                        '¿En qué puedo ayudarte?'
                    ],
                    theme: {
                        primaryColor: '#4299e1',
                        backgroundColor: '#ffffff',
                        textColor: '#2d3748',
                        embedded: true ,
                        // Cabecera del chat
                        header: {
                            backgroundColor: '#4299e1',
                            textColor: '#ffffff',
                            fontSize: '8px',
                            padding: '2px',
                             height: 'auto',
                            minHeight: 'auto'
                        },
                        messages: {
                            // Mensajes del bot
                            incoming: {
                                backgroundColor: '#EBF8FF',
                                textColor: '#2D3748',
                                fontSize: '16px',
                                padding: '12px',
                                margin: '8px',
                                borderRadius: '12px 12px 12px 0'
                            },
                            // Mensajes del usuario
                            outgoing: {
                                backgroundColor: '#4299E1',
                                textColor: '#FFFFFF',
                                fontSize: '16px',
                                padding: '12px',
                                margin: '8px',
                                borderRadius: '12px 12px 0 12px'
                            }
                        },

                        
                    },
                    i18n: {
                        es: {
                            title: 'Asistente AI',
                            subtitle: 'Estamos aquí para ayudarte 24/7',
                            inputPlaceholder: 'Escribe tu pregunta...',
                            footer: ''
                        }
                    }
                });

            } catch (err) {
                console.error('Error al inicializar el chat:', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        initChat();
    }, []);

    // Botón para reintentar con logs
    const handleRetry = () => {
        console.log('Reintentando inicialización del chat...');
        window.location.reload();
    };

    return (
        <div className="n8n-chat-page">
            <div className="chat-container">
                <div className="chat-header">
                    <h1>Asistente AI</h1>
                    <p>Interactúa con nuestro agente inteligente para obtener ayuda instantánea</p>
                </div>
                
                <div className="chat-section">
                    {isLoading && (
                        <div className="loading-message">
                            Iniciando el chat...
                        </div>
                    )}
                    {error && (
                        <div className="error-message">
                            <p>Error: {error}</p>
                            <p>URL configurada: {process.env.REACT_APP_N8N_WEBHOOK_URL || 'No configurada'}</p>
                            <button onClick={handleRetry} className="retry-button">
                                Reintentar
                            </button>
                        </div>
                    )}
                    <div id="n8n-chat" className="chat-embed-container" />
                    
                </div>
            </div>
        </div>
    );
}

export default N8nChat;