// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="home-container">
      <div className="hero-section">
        <h1>Plataforma de Agentes IA</h1>
        <p className="hero-subtitle">
          Crea, gestiona y despliega agentes de IA personalizados para tu negocio
        </p>
        <div className="cta-buttons">
          <Link to="/register" className="cta-button primary">
            Comenzar Ahora
          </Link>
          <Link to="/login" className="cta-button secondary">
            Iniciar Sesión
          </Link>
        </div>
      </div>

      <div className="features-section">
        <h2>Características Principales</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>🤖 Agentes Personalizados</h3>
            <p>Crea agentes de IA especializados para diferentes tareas y casos de uso</p>
          </div>
          <div className="feature-card">
            <h3>📊 Análisis en Tiempo Real</h3>
            <p>Monitorea el rendimiento de tus agentes con métricas detalladas</p>
          </div>
          <div className="feature-card">
            <h3>🔒 Seguridad Integrada</h3>
            <p>Protección de datos y privacidad de nivel empresarial</p>
          </div>
          <div className="feature-card">
            <h3>⚡ Fácil Integración</h3>
            <p>Integra tus agentes con tus sistemas existentes sin complicaciones</p>
          </div>
        </div>
      </div>

      <div className="how-it-works">
        <h2>¿Cómo Funciona?</h2>
        <div className="steps">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Crea tu Cuenta</h3>
            <p>Regístrate y configura tu perfil en minutos</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Configura tu Agente</h3>
            <p>Personaliza el comportamiento y conocimientos de tu agente</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>¡Listo para Usar!</h3>
            <p>Tu agente estará listo para interactuar con tus usuarios</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
